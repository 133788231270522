import {Constants} from "../constants";

export function setGlobalErrors(errors) {
    return function (dispatch) {
      dispatch({type: Constants.LOGOUT});
      return dispatch({type: Constants.SET_GLOBAL_ERROR, payload: errors});
    }
}

export function resetGlobalErrors() {
    return function (dispatch) {
        return dispatch({type: Constants.RESET_GLOBAL_ERRORS});
    }
}
